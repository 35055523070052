import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';
import { urlBooking } from 'api';
import { get } from 'api/requests';

// mapboxgl.accessToken = process.env.REACT_APP_API_URL_BASE_MAPS_API_TOKEN;
const STYLE_URL = process.env.REACT_APP_API_URL_BASE_MAPS_API_STYLES_URL;


const GREEN_COLOR = '#93ca00';
const PURPLE_COLOR = '#9A68C2';

export default function BookingMap({ booking }) {
	const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const [coord, setCoord] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [driverM, setDriverM] = useState([]);
  let arrayCoordinates = [];

  useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
      style: STYLE_URL,
			zoom: 10,
			center: [...booking.origin_geojson?.coordinates]
		});
    map.addControl(new mapboxgl.NavigationControl());
    map.on('load', () => {
      booking?.route_info && getPolyline(map);
      booking.origin_geojson && originPoint(map, booking.origin_geojson, 'A');
      booking?.end_geojson && booking?.stops.length === 0 && originPoint(map, booking.end_geojson, '1')
      getRouteTracker();
      setMap(map);
    });
	}, []); // eslint-disable-line

  const getRouteTracker = () => {
    get(`${urlBooking}/${booking._id}/route_tracker`, {})
      .then(json => {
        return json.data;
      })
      .then(data => {
        if (data && data.points) {
					const points = data.points.map(point => point.loc_geojson && [ ...point.loc_geojson.coordinates ]);
          setCoord(points);
        }
      })
      .catch(error => {});
  }

  useEffect(() => {
    map && coord?.length > 0 && getPolylineRoute(map, coord)
  },[map, coord]) // eslint-disable-line

  const getPolylineRoute = (map, polylineCoordinates) => {
    const source = 'route-line';
    const layer = 'route-line';
    removePolyline(layer);

    map.addSource(source, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: polylineCoordinates,
        },
      },
    });

    map.addLayer({
      id: layer,
      type: 'line',
      source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#9A68C2',
        'line-width': 3,
        'line-opacity': 0.9
      },
    });
  };

  const getPolyline = (map) => {
    const source = 'route-line-map';
    const layer = 'route-line-map';
    const polylineCoordinates = booking.route_info?.polyline && polyline
				.decode(booking.route_info.polyline)
				.map((coord) => coord.reverse())

    map.addSource(source, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: polylineCoordinates,
        },
      },
    });

    map.addLayer({
      id: layer,
      type: 'line',
      source,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#9A68C2',
        'line-width': 3,
        'line-opacity': 0.9
      },
    });
  };

  const vehicleAsset = {
    '5c71b03a58b9ba10fa6393cf':'url("/images/tracking/driver-with-box.png")',
    '5e2a872a0be444bd2e0308b1':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3a':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3b':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3c':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3d':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3e':'url("/images/tracking/freight-vehicle.png")',
    '5e27bacd76617a0004575ae2':'url("/images/tracking/freight-vehicle.png")',
    '62e2ae08790a6a0004ab0a3f':'url("/images/tracking/freight-vehicle.png")',
    '6654a335f8f2586515b6bb51':'url("/images/tracking/freight-vehicle.png")',
    '6453e0394a73fe93b95e7fbe':'url("/images/tracking/car.png")',
    '57b28033f0350b00035d0ade':'url("/images/tracking/driver-picap.png")',
    '62ab4eac05c75b00040bf620':'url("/images/tracking/driver-picap.png")'
  }

  const paintDriver = (map, coord) => {
    clearDriverM();
    const el = document.createElement('div');
    el.className = 'marker-driver';
    el.style.backgroundImage = vehicleAsset[booking.requested_service_type_id];
    el.style.width = '45px';
    el.style.height = '55px';
    el.style.backgroundSize = 'contain';
    el.style.backgroundRepeat = 'no-repeat';
    let marker = new mapboxgl.Marker(el).setRotation(booking.served_vehicle.loc_course ? booking.served_vehicle.loc_course : 0).setLngLat([...coord]).addTo(map);
    setDriverM(prev => prev.concat(marker));
    return marker;
  }

  const clearDriverM = () => driverM.forEach((marker) => marker.remove());

  useEffect(() => {
    if (map && booking.served_vehicle && booking.served_vehicle?.loc_geojson && [1, 2, 3, 5, 6, 7].indexOf(booking.status_cd) !== -1) {
      paintDriver(map, booking.served_vehicle?.loc_geojson?.coordinates)
    } else {
      clearDriverM();
    }
  }, [map, booking]); // eslint-disable-line

  useEffect(() => {
    if (map && booking.served_vehicle && [1, 2, 3, 5, 6, 7].indexOf(booking.status_cd) !== -1) {
      getRouteTracker();
    }
  }, [map, booking]); // eslint-disable-line

  const originPoint = (map, geojson, letter) => {
    const color = [4, 6, 7].indexOf(booking?.status_cd) !== -1 ? GREEN_COLOR : PURPLE_COLOR;
    const el = document.createElement('div');
    el.innerHTML = `
      <div class="marker-circle">
        <span>${letter}</span> 
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 28 28">
          <g fill="none" fill-rule="evenodd">
            <g fill=${color} transform="translate(0 .26)">
              <circle cx="13.536" cy="13.536" r="13.536" fill-opacity=".2"/>
              <circle cx="13.536" cy="13.536" r="11.499"/>
            </g>
          </g>
        </svg>
      </div>`;
    el.id = 'marker';
    return new mapboxgl.Marker(el).setLngLat([...geojson?.coordinates]).addTo(map);
  }

	const newPoint = (title, geojson, color) => {
    zoomIn();
    clearMarkers();
    const el = document.createElement('div');
    el.innerHTML = `
      <div class="marker-circle">
        <span>${title}</span> 
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 28 28">
          <g fill="none" fill-rule="evenodd">
            <g fill=${color} transform="translate(0 .26)">
              <circle cx="13.536" cy="13.536" r="13.536" fill-opacity=".2"/>
              <circle cx="13.536" cy="13.536" r="11.499"/>
            </g>
          </g>
        </svg>
      </div>`;
    el.id = 'marker';
    let marker = new mapboxgl.Marker(el).setLngLat(geojson).addTo(map);
    setMarkers(prev => prev.concat(marker));
    return marker;
  }

  const clearMarkers = () => markers.forEach((marker) => marker.remove());

  const removePolyline = (removeLine) => {
    if (map.getLayer(removeLine)) {
      map.removeLayer(removeLine);
    }
    if (map.getSource(removeLine)) {
      map.removeSource(removeLine);
    }
  }

  const coordinates = () => {
    booking.stops.map((stop) =>
      arrayCoordinates.push([...stop.address_geojson?.coordinates]
    ));
    if (booking.origin_geojson) arrayCoordinates.push(booking.origin_geojson.coordinates);
    if (booking.served_vehicle && [1, 2, 3, 5, 6, 7].indexOf(booking.status_cd) !== -1) arrayCoordinates.push(booking.served_vehicle?.loc_geojson?.coordinates);
    return arrayCoordinates;
  }

	const zoomIn = () => {
    const bounds = coordinates().reduce((bounds, coord) => {
      return bounds.extend(coord);
    }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

    map.fitBounds(bounds, {
      padding: 80,
      maxZoom: 14,
      duration: 2000
    }); 
  }

	useEffect(() => {
		// Show the stops on the map
		if (map && booking?.stops) {
			booking.stops.map((stop) => {
        const color = stop.finished ? GREEN_COLOR : PURPLE_COLOR;
        return newPoint('A', stop.address_geojson?.coordinates, color);
      })
		}
	}, [map]); // eslint-disable-line

  useEffect(() => {
		// Show the stops on the map
		if (map && booking?.stops) {
			booking.stops.map((stop, index) => {
        const color = stop.finished ? GREEN_COLOR : PURPLE_COLOR;
        return newPoint(stop.is_return_stop ? 'A' : index + 1, stop.address_geojson?.coordinates, color)
      })
		}
	}, [map, booking]); // eslint-disable-line

  return (
    <div
      className="map-container map-container-responsive"
      ref={mapContainerRef}
      style={{ position: 'relative', top: '20px',  boxShadow: '0 2px 6px 0 rgb(0 0 0 / 14%)', height: 'calc(100vh - 200px)' }}
    />
  )
}