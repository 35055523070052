export const INITIAL_PAGE = 1;
export const PER_PAGE = 10;
export const SERVICE_TYPE_ID = '5c71b03a58b9ba10fa6393cf';
export const FREIGHT_SERVICE_TYPE_ID = '5e2a872a0be444bd2e0308b1';
export const FREIGHT_MOTORCYCLE_TRAILER_SERVICE_TYPE_ID =
  '62e2ae08790a6a0004ab0a3a';
export const FREIGHT_CARRY_SERVICE_TYPE_ID = '62e2ae08790a6a0004ab0a3b';
export const FREIGHT_NHR_TRAILER_SERVICE_TYPE_ID = '62e2ae08790a6a0004ab0a3c';
export const FREIGHT_NKR_TRAILER_SERVICE_TYPE_ID = '62e2ae08790a6a0004ab0a3d';
export const FREIGHT_NPR_SERVICE_TYPE_ID = '62e2ae08790a6a0004ab0a3e';
export const FREIGHT_TRAILER_SERVICE_TYPE_ID = '62e2ae08790a6a0004ab0a3f';
export const FREIGHT_VEHICLE_ID = '5e27bacd76617a0004575ae2';
export const RENT_SERVICE_TYPE_ID = '57b28033f0350b00035d0ade';
export const RENT = 'rent';
export const PIBOX_CAR_SERVICE_TYPE_ID = '6453e0394a73fe93b95e7fbe';
export const FREIGHT_NHR_REFRIGERATED_SERVICE_TYPE_ID = '6654a335f8f2586515b6bb51';

export const COUNTRY_CODE = {
  es: ['5bb16dc54cdce20c0126f9a6', 'CO', 'Colombia', '57', 'COP'],
  es_mx: ['5bb16f294cdce20c0126f9a7', 'MX', 'México', '52', 'MXN'],
  es_pe: ['5cec29e1fc829f001b3a1a5c', 'PE', 'Perú', '51', 'PEN'],
  pt: ['5d0d74385300bd00043c5e23', 'BR', 'Brazil', '55', 'BRL'],
  es_gt: ['5d5b76105cd4fc000417c046', 'GT', 'Guatemala', '502', 'GTQ'],
  es_pyg: ['5dd494a92558d10004dea8ed', 'PY', 'Paraguay', '595', 'PYG'],
};

export const COUNTRY_LANGUAJE = {
  '5bb16dc54cdce20c0126f9a6': 'es',
  '5bb16f294cdce20c0126f9a7': 'es_mx',
  '5cec29e1fc829f001b3a1a5c': 'es_pe',
  '5d0d74385300bd00043c5e23': 'pt',
  '5d5b76105cd4fc000417c046': 'es_gt',
  '5dd494a92558d10004dea8ed': 'es_pyg',
};

export const COUNTRIES = [
  { _id: '5bb16dc54cdce20c0126f9a6', name: 'Colombia' },
  { _id: '5bb16f294cdce20c0126f9a7', name: 'México' },
  // { _id: '5cec29e1fc829f001b3a1a5c', name: 'Perú' },
  // { _id: '5d0d74385300bd00043c5e23', name: 'Brasil' },
  { _id: '5d5b76105cd4fc000417c046', name: 'Guatemala' },
  { _id: '5dd494a92558d10004dea8ed', name: 'Paraguay' },
];

export const COUNTRIES_IDS_BY_NAME = {
  colombia: { _id: '5bb16dc54cdce20c0126f9a6', name: 'Colombia' },
  mexico: { _id: '5bb16f294cdce20c0126f9a7', name: 'México' },
  // { _id: '5cec29e1fc829f001b3a1a5c', name: 'Perú' },
  // { _id: '5d0d74385300bd00043c5e23', name: 'Brasil' },
  guatemala: { _id: '5d5b76105cd4fc000417c046', name: 'Guatemala' },
  paraguay: { _id: '5dd494a92558d10004dea8ed', name: 'Paraguay' },
};

export const STATUSES = {
  900: 'status.created',
  901: 'status.acceptedByDriver',
  902: 'status.inProcess',
  0: 'status.waitingForPickUp', // waiting_for_pick_up
  1: 'status.pickedUp', // picked_up
  2: 'status.delivered', // delivered
  3: 'status.cancelled', // cancelled
  4: 'status.notReceived', // not_received
  5: 'status.returned', // returned
};

export const TEXT_EVENTS = {
  900: 'tracking.events.created.text',
  901: 'tracking.events.accepted.text',
  902: 'tracking.events.inProcess.text',
  1: 'tracking.events.pickedUp.text',
  2: 'tracking.events.delivered.text',
  3: 'tracking.events.cancelled.text',
  4: 'tracking.events.notReceived.text',
  5: 'tracking.events.returned.text',
};

export const INDUSTRY_TYPE_CD = [
  { _id: 1, name: 'industryTypeCd.eCommerce' },
  { _id: 2, name: 'industryTypeCd.flowers' },
  { _id: 3, name: 'industryTypeCd.food' },
  { _id: 4, name: 'industryTypeCd.supermarket' },
  { _id: 5, name: 'industryTypeCd.alcohol' },
  { _id: 6, name: 'industryTypeCd.documents' },
  { _id: 7, name: 'industryTypeCd.electronics' },
  { _id: 8, name: 'industryTypeCd.clothing' },
  { _id: 9, name: 'industryTypeCd.pharmacy' },
  { _id: 10, name: 'industryTypeCd.presents' },
  { _id: 11, name: 'industryTypeCd.logistics' },
  { _id: 12, name: 'industryTypeCd.services' },
  { _id: 13, name: 'industryTypeCd.other' },
  { _id: 14, name: 'industryTypeCd.frozen_products' },
  { _id: 15, name: 'industryTypeCd.pet_products' },
  { _id: 16, name: 'industryTypeCd.toys' },
  { _id: 17, name: 'industryTypeCd.beauty_products' },
  { _id: 18, name: 'industryTypeCd.decorative_home' },
];

export const SIZE_CD = {
  0: 'size.extraSmall',
  1: 'size.small',
  2: 'size.medium',
  3: 'size.large',
};

export const REASON_NOT_RECEIVED = {
  0: 'reasonNotReceived.none',
  1: 'reasonNotReceived.incorrectAddress',
  2: 'reasonNotReceived.rejectedByClient',
  3: 'reasonNotReceived.missingClient',
  4: 'reasonNotReceived.clientDoesNotHaveMoney',
};

export const REASON_CANCELED_PICKUP = {
  0: 'reasonCanceledPickup.notAvailable',
  1: 'reasonCanceledPickup.wrongAddress',
  2: 'reasonCanceledPickup.closed',
};

export const PAYMENT_METHODS = {
  1: 'app.paymentMethods.cash',
  3: 'app.paymentMethods.creditCard',
  4: 'app.paymentMethods.wallet',
};

export const PAYMENT_METHODS_PIBOX = {
  1: 'app.paymentMethods.cash',
  4: 'app.paymentMethods.wallet',
};

export const BOOKING_STATUSES = {
  0: 'app.bookingStatuses.lookingForDriver', // looking_for_driver
  1: 'app.bookingStatuses.driverOnRoad', // driver_en_route
  2: 'app.bookingStatuses.waitingForPassenger', // waiting_for_passenger - rent
  3: 'app.bookingStatuses.passengerOnBoard', // passenger_on_board - rent
  4: 'app.bookingStatuses.finishedByDriver', // finished_by_driver
  5: 'app.bookingStatuses.pickingUpPackage', // picking_up_package
  6: 'app.bookingStatuses.packageOnBoard', // package_on_board
  7: 'app.bookingStatuses.deliveringPackage', // delivering_package
  14: 'app.bookingStatuses.reservationRunning', // reservation_running
  15: 'app.bookingStatuses.reserved', // reserved
  16: 'app.bookingStatuses.lookingForReservation', // looking_for_reservation
  20: 'app.bookingStatuses.handledByOps', // handled_by_ops
  100: 'app.bookingStatuses.canceledByDriver', // canceled_by_driver
  101: 'app.bookingStatuses.expired', // expred
  102: 'app.bookingStatuses.canceledByPassenger', // canceled_by_passenger
  104: 'app.bookingStatuses.canceledByOps', // Canceled_by_ops
  107: 'app.bookingStatuses.finishedByOps',
  109: 'app.bookingStatuses.scheduled',
  110: 'app.bookingStatuses.planned',
  111: 'app.bookingStatuses.acceptedPlannedRequest',
  120: 'app.bookingStatuses.assigned',
  124: 'app.bookingStatuses.groupedInReserve',
  201: 'app.bookingStatuses.optimizing',
  202: 'app.bookingStatuses.optimized',
  300: 'app.bookingStatuses.overridenByOps', // overriden_by_ops
};

export const PACKAGES_STATUSES = {
  0: 'app.packageStatus.waitingForPickup',
  1: 'app.packageStatus.packagePickedUp',
  2: 'app.packageStatus.packageDelivered',
  3: 'app.packageStatus.packageNotCollected',
  4: 'app.packageStatus.notReceivePackage',
  5: 'app.packageStatus.returnedPackage',
  15: 'app.packageStatus.notVisited',
};

export const PQRS = [
  { value: 'question', name: 'pqrs.question' },
  { value: 'complain', name: 'pqrs.complain' },
  { value: 'claim', name: 'pqrs.claim' },
];

export const DELIVERY_SALE_STATUS = {
  pending: 'delivery_sale_status.pending', // pending
  handling: 'delivery_sale_status.handling', // handling
  ready_to_ship: 'delivery_sale_status.readyToShip', // ready_to_ship
  shipped: 'delivery_sale_status.shipped', // shipped
  delivered: 'delivery_sale_status.delivered', // delivered
  not_delivered: 'delivery_sale_status.notDelivered', // not_delivered
  cancelled: 'delivery_sale_status.cancelled', // cancelled
  null: 'delivery_sale_status.notStatus',
};

export const DELIVERY_SALE_SHOPIFY_STATUS = {
  fulfilled: 'delivery_sale_shopify_status.prepared',
  pending: 'delivery_sale_shopify_status.pending',
  unfulfilled: 'delivery_sale_shopify_status.unfulfilled',
  'partially-fulfilled': 'delivery_sale_shopify_status.partiallyFulfilled',
  scheduled: 'delivery_sale_shopify_status.scheduled',
  'on-hold': 'delivery_sale_shopify_status.onHold',
  fail: 'delivery_sale_shopify_status.fail',
  null: 'delivery_sale_status.notStatus',
};

export const DELIVERY_SALE_SHOPIFY_STATUS_FILTERS = {
  fulfilled: 'delivery_sale_shopify_status.prepared',
  nulo: 'delivery_sale_status.notStatus',
};

export const DELIVERY_SALE_WOOCOMMERCE_STATUS = {
  pending: 'delivery_sale_woocommerce_status.pending',
  processing: 'delivery_sale_woocommerce_status.processing',
  'on-hold': 'delivery_sale_woocommerce_status.onHold',
  completed: 'delivery_sale_woocommerce_status.completed',
  cancelled: 'delivery_sale_woocommerce_status.cancelled',
  refunded: 'delivery_sale_woocommerce_status.refunded',
  failed: 'delivery_sale_woocommerce_status.failed',
  trash: 'delivery_sale_woocommerce_status.trash',
  null: 'delivery_sale_status.notStatus',
};

export const SALES_VTEX_STATUS = {
  'waiting-for-sellers-confirmation':
    'delivery_sale_vtex_status.waitingConfirmation',
  'payment-pending': 'delivery_sale_vtex_status.paymentPending',
  'payment-approved': 'delivery_sale_vtex_status.paymentApproved',
  'ready-for-handling': 'delivery_sale_vtex_status.readyForHandling',
  'window-to-cancel': 'delivery_sale_vtex_status.windowToCancel',
  handling: 'delivery_sale_vtex_status.handling',
  invoiced: 'delivery_sale_vtex_status.invoiced',
  canceled: 'delivery_sale_vtex_status.canceled',
};

export const INTEGRATIONS = [
  { id: '605948ee1515ed0744254297', name: 'Mercado envíos' },
  { id: 'Shopify', name: 'Shopify' },
  { id: '630432d1b084845c993aee0a', name: 'Woocommerce' },
];
export const INTEGRATIONS_OPTIONS = [
  { id: 'mercadoflex', name: 'Mercado envíos' },
  { id: 'shopify', name: 'Shopify' },
  { id: 'woocommerce', name: 'Woocommerce' },
  { id: 'vtex', name: 'Vtex' },
];

export const ROLES = [
  {
    id: '61096138285f181e93a462a7',
    name: 'app.roles.administrator',
    value: 'company_admin',
  },
  {
    id: '61096135285f181e93a462a6',
    name: 'app.roles.operator',
    value: 'company_ops',
  },
  {
    id: '633335b0d60abe0004881d91',
    name: 'app.roles.viewer',
    value: 'pibox_viewer',
  },
  {
    id: '647a654c277f6e389aa2e8e8',
    name: 'app.roles.financial',
    value: 'pibox_financial'
  }
];

export const ROLES_COMPANY = {
  '61096138285f181e93a462a7': 'app.roles.administrator',
  '61096135285f181e93a462a6': 'app.roles.operator',
  '633335b0d60abe0004881d91': 'app.roles.viewer',
  '647a654c277f6e389aa2e8e8': 'app.roles.financial'
};

export const ROLE_COMPANY_NAME = {
  company_admin: 'app.roles.administrative',
};

export const HOURS_DEDICATED = [4, 5, 6, 7, 8, 9, 10, 11, 12];

export const OPTIMIZATION_STOPS_FILE_STATUSES = {
  0: 'app.optimizationStopsFileStatuses.processing',
  1: 'app.optimizationStopsFileStatuses.succeed',
  2: 'app.optimizationStopsFileStatuses.failed',
  3: 'app.optimizationStopsFileStatuses.cancelled',
};

export const BOOKING_EVENTS = {
  0: 'app.eventBooking.canceledbyPassenger',
  1: 'app.eventBooking.ratedbyPassenger',
  2: 'app.eventBooking.destinationAddedPerPassenger',
  3: 'app.eventBooking.addedOriginAddressByPassenger',
  4: 'app.eventBooking.canceledByPassengerOnWeb', // canceled_by_passenger_on_web
  20: 'app.eventBooking.acceptedByDriver',
  21: 'app.eventBooking.driverHasArrived',
  22: 'app.eventBooking.pickedUpByDriver',
  23: 'app.eventBooking.finishedByDriver',
  24: 'app.eventBooking.ratedByDriver',
  25: 'app.eventBooking.destinationAddedByDriver',
  26: 'app.eventBooking.canceledByDriver',
  27: 'app.eventBooking.fakeFinished',
  28: 'app.eventBooking.arrivalAtPlaceOfDelivery',
  29: 'app.eventBooking.PackageNotReceive',
  30: 'app.eventBooking.canceledByStore',
  31: 'app.eventBooking.ordering',
  32: 'app.eventBooking.returningToTheOrigin',
  33: 'app.eventBooking.orderConfirmed',
  34: 'app.eventBooking.canceledDueToOperations',
  35: 'app.eventBooking.canceledByInsufficientFunds',
  36: 'app.eventBooking.serviceStarted',
  37: 'app.eventBooking.serviceFinished',
  38: 'app.eventBooking.serviceFinishedByOps',
  39: 'app.eventBooking.managedByOps',
  40: 'app.eventBooking.addedOriginAddressByDriver',  
  41: 'app.eventBooking.driverNearThePickUpPoint',
  42: 'app.eventBooking.reservationStarted',
  43: 'app.eventBooking.assignedToReservation',
  44: 'app.eventBooking.bookingForReservation',
  45: 'app.eventBooking.driverCloseToDropoff',
  46: 'app.eventBooking.finishedByAccident',
  101: 'app.eventBooking.canceledByCron',
  102: 'app.eventBooking.finishedByCron',
  103: 'app.eventBooking.releasedByCron',
  104: 'app.eventBooking.routed',
  105: 'app.eventBooking.routing',
  106: 'app.eventBooking.expred',
  109: 'app.eventBooking.scheduled',
  200: 'app.eventBooking.handledByOps',
  201: 'app.eventBooking.resetToLookingForDriver',
  301: 'app.eventBooking.unfinishedBySac',
  302: 'app.eventBooking.skipRateByPassenger'
};

export const SENDER_TYPE = {
  PASSENGER: 0,
  DRIVER: 1,
  PICAP: 2,
};

export const PROCESSING_FILES = {
  0: 'stateProcessing',
  1: 'stateFinished',
  2: 'stateCancelled',
  3: 'stateFailed',
};

export const IDS_SERVICES_TYPE_PIBOX = {
  '5c71b03a58b9ba10fa6393cf': 'app.serviceType.courier',
  '5e2a872a0be444bd2e0308b1': 'app.serviceType.freight',
  '62e2ae08790a6a0004ab0a3a': 'app.serviceType.freightMotorcycleTrailer',
  '62e2ae08790a6a0004ab0a3b': 'app.serviceType.freightCarry',
  '62e2ae08790a6a0004ab0a3c': 'app.serviceType.freightNHR',
  '62e2ae08790a6a0004ab0a3d': 'app.serviceType.freightNKR',
  '62e2ae08790a6a0004ab0a3e': 'app.serviceType.freightNPR',
  '62e2ae08790a6a0004ab0a3f': 'app.serviceType.freightTrailer',
  '6654a335f8f2586515b6bb51': 'app.serviceType.freightRefrigeratedNHR',
  '6453e0394a73fe93b95e7fbe': 'app.serviceType.car',
  '57b28033f0350b00035d0ade': 'app.serviceType.rent'
};

export const COMPANY_DOCUMENT_HISTORY_STATUS = {
  0: 'app.setting.companyRegister.companyDocumentHistoryStatus.pending', // pending
  1: 'app.setting.companyRegister.companyDocumentHistoryStatus.approved', // approved
  2: 'app.setting.companyRegister.companyDocumentHistoryStatus.archived', // archived
};

export const FILTER_SERVICE_TYPES = {
  delivery: 'filterServiceTypes.delivery',
  freight: 'filterServiceTypes.freight',
  rent: 'filterServiceTypes.rent',
};

export const CREDIT_CARD_CENTURY = '20';

export const SERVICE_TYPE_USER = [
  {
    label: 'serviceTypeUser.pibox',
    requestable_service_type_group_cd: 'pibox',
    is_enabled: true,
    id: null,
  },
  {
    label: 'serviceTypeUser.freight',
    requestable_service_type_group_cd: 'freight',
    is_enabled: true,
    id: null,
  },
  {
    label: 'serviceTypeUser.rent',
    requestable_service_type_group_cd: 'rent',
    is_enabled: false,
    id: null,
  },
];

export const SERVICE_TYPE_USER_TEXT = {
  pibox: 'serviceTypeUser.pibox',
  freight: 'serviceTypeUser.freight',
  rent: 'serviceTypeUser.rent',
};

export const IDS_SERVICES_TYPE_RENT = {
  '57b28033f0350b00035d0ade': 'app.serviceType.rent',
  '62ab4eac05c75b00040bf620': 'app.serviceType.vipMotorcycle'
};

export const PROMO_CODE_STATUS = {
  0: 'promoCode.status.notAssigned',
  1: 'promoCode.status.assigned',
  2: 'promoCode.status.used',
  3: 'promoCode.status.expired',
  4: 'promoCode.status.cancelled',
  5: 'promoCode.status.exhaustedBalance',
};

export const INSURANCE_VALUES = {
  0: '$100.000',
  1: '$200.000 - $300.000',
  2: '$300.000 - $1.000.000',
  3: '$1.000.000 - $3.000.000',
  4: 'Superior a $3.000.000',
};

export const TAX_LIABILITIES = {
  0: 'tax_liabilities.withholding_tax_on_income',
  1: 'tax_liabilities.withholding_tax_on_sales',
  2: 'tax_liabilities.common_regime_sales',
  3: 'tax_liabilities.external_reporting_agent',
  4: 'tax_liabilities.mandatory_electronic_invoicing_entity_model_2242',
};

export const COMPANY_LEGAL_ENTITY_TYPES = {
  1: 'companyLegalEntityTypes.natural',
  2: 'companyLegalEntityTypes.legal',
};

export const COLORS_BY_STATUS_FOR_DOCUMENT_HISTORY = [
  '#FFF5E5', // 'pending'
  '#EFFAEE', // 'approved'
  '#FFF5E5', // 'archived'
  '#FFE9EE', // 'rejected'
  'none', // 'incomplete'
];

export const FILES_CD = [
  'rut_document',
  'legal_representative_document',
  'certificate_of_existence_and_legal_representation',
  'bank_certification',
  'last_year_financial_statements',
  'last_income_tax_return',
];

export const COMPANY_REGISTRATION_STATUSES = {
  unapproved: 'unapproved',
  pending: 'pending',
  refused: 'refused',
  approved: 'approved',
  pending_update: 'pending_update',
};

// data from https://github.com/RubyMoney/money/blob/master/config/currency_iso.json
export const CURRENCIES = {
  COP: { iso: 'COP', subUnitToUnit: 100, symbol: '$' },
  PEN: { iso: 'PEN', subUnitToUnit: 100, symbol: 'S/' },
  MXN: { iso: 'MXN', subUnitToUnit: 100, symbol: '$' },
  ARS: { iso: 'MXN', subUnitToUnit: 100, symbol: '$' },
  CLP: { iso: 'CLP', subUnitToUnit: 1, symbol: '$' },
  BRL: { iso: 'BRL', subUnitToUnit: 100, symbol: 'R$' },
  GTQ: { iso: 'GTQ', subUnitToUnit: 100, symbol: 'Q' },
  PYG: { iso: 'PYG', subUnitToUnit: 1, symbol: '₲' },
  USD: { iso: 'USD', subUnitToUnit: 100, symbol: '$' },
  DOP: { iso: 'DOP', subUnitToUnit: 100, symbol: '$' },
};

export const PREPACKAGES_STATUSES = {
  0: 'prepackagesStatuses.pooled', // pooled
  1: 'prepackagesStatuses.locked', // locked
  2: 'prepackagesStatuses.grouped', // grouped
  3: 'prepackagesStatuses.delivered', // delivered
  4: 'prepackagesStatuses.cancelled', // cancelled
  5: 'prepackagesStatuses.returned', // returned
  6: 'prepackagesStatuses.groupedBySystem', // grouped_by_system
  7: 'prepackagesStatuses.processing' // processing
}

export const BILL_PAYMENT_STATUSES = {
  1: 'billPaymentStatuses.approved',
  2: 'billPaymentStatuses.refused',
  3: 'billPaymentStatuses.pending',
  4: 'billPaymentStatuses.failed',
  5: 'billPaymentStatuses.expired',
  6: 'billPaymentStatuses.waitingAnswer'
}

export const LEGAL_CUSTOMER_TYPE = {
  '0': 'legalCustomerType.natural',
  '1': 'legalCustomerType.legal',
};

export const DOCUMENT_TYPE = {
  'CC': 'documentType.cc',
  'NIT': 'documentType.nit',
};

export const BOOKING_STATUSES_TO_ANALYTICS = {
  0: 'app.bookingStatuses.lookingForDriver', // looking_for_driver
  1: 'app.bookingStatuses.driverOnRoad', // driver_en_route
  4: 'app.bookingStatuses.finishedByDriver', // finished_by_driver
  5: 'app.bookingStatuses.pickingUpPackage', // picking_up_package
  6: 'app.bookingStatuses.packageOnBoard', // package_on_board
  7: 'app.bookingStatuses.deliveringPackage', // delivering_package
  101: 'app.bookingStatuses.expired', // expred
  102: 'app.bookingStatuses.canceledByPassenger', // canceled_by_passenger
};

export const ALL_IDS_SERVICES_TYPE = {
  '5c71b03a58b9ba10fa6393cf': 'app.serviceType.courier',
  '5e2a872a0be444bd2e0308b1': 'app.serviceType.freight',
  '62e2ae08790a6a0004ab0a3a': 'app.serviceType.freightMotorcycleTrailer',
  '62e2ae08790a6a0004ab0a3b': 'app.serviceType.freightCarry',
  '62e2ae08790a6a0004ab0a3c': 'app.serviceType.freightNHR',
  '62e2ae08790a6a0004ab0a3d': 'app.serviceType.freightNKR',
  '62e2ae08790a6a0004ab0a3e': 'app.serviceType.freightNPR',
  '62e2ae08790a6a0004ab0a3f': 'app.serviceType.freightTrailer',
  '6654a335f8f2586515b6bb51': 'app.serviceType.freightRefrigeratedNHR',
  '6453e0394a73fe93b95e7fbe': 'app.serviceType.car',
  '57b28033f0350b00035d0ade': 'serviceTypeUser.rent'
};

export const DECLARED_VALUE_ANALYTICS = {
  'boundary_01': 'analytics.filter.range1',
  'boundary_02': 'analytics.filter.range2',
  'boundary_03': 'analytics.filter.range3',
  'boundary_04': 'analytics.filter.range4'
}

export const TERMS_GENERAL_URL = '/terms/general-terms.pdf';
export const TERMS_CORPORATE_URL = '/terms/terms-and-conditions-of-corporate-use-pibox.pdf';

export const COMMODATE_STATUSES = {
  'commodate.status.waiting_borrower_ack': 1,
  'commodate.status.partial_borrowed': 2,
  'commodate.status.borrowed': 3,
  'commodate.status.waiting_lender_ack': 4,
  'commodate.status.partial_returned': 5,
  'commodate.status.returned': 6,
  'commodate.status.expired': 7,
  'commodate.status.canceled_by_lender': 8,
  'commodate.status.returned_with_incident': 10,
  'commodate.status.noRefund': 11
}

export const STEP_REQUEST_COMMODATE = {
  0: 'fetch',
  1: 'create'
}

export const CATEGORIES_COMMODATE = {
  0: 'commodate.category.dataphone',
  1: 'commodate.category.basket',
  2: 'commodate.category.case',
  3: 'commodate.category.other'
}

export const ADDONS = [
  'delivery_validation_code',
  'pickup_validation_code',
  'collect_cash_payments',
  'delivery_whatsapp_messages',
  'commodate_loans',
  'meli_co_gateway'
]

export const PQR_STATUSES = {
  0: 'claims.status.new',
  1: 'claims.status.analysis',
  2: 'claims.status.initialResponse',
  3: 'claims.status.reconsideration',
  4: 'claims.status.closed'
}


export const BALANCE_CORPORATIVE_PROMOCODE_TYPES = {
  0: 'default',
  1: 'pibox',
  2: 'picap'
}

export const TYPE_OF_SERVICE = {
  'express': 'typeOfService.express',
  'scheduled': 'typeOfService.scheduled',
  'next_day': 'typeOfService.nextDay',
  'same_day': 'typeOfService.sameDay',
  'rent': 'typeOfService.rent'
}

export const BALANCE_STATUS = {
  1: 'balances.table.filter.status.current',
  3: 'promoCode.status.expired',
  4: 'promoCode.status.cancelled',
  5: 'promoCode.status.exhaustedBalance',
}

export const SALE_TYPE_ORDER = {
  FLEX: 'FLEX',
  FULL: 'FULL'
};